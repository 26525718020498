// @ts-nocheck
import {Routes} from '@angular/router';

export const NG_DOC_ROUTING: Routes = [
	
	{
		path: 'api',
		title: `API References`,
		loadChildren: () => import('./api/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocDefaultSearchEngineOptions',
		title: `NgDocDefaultSearchEngineOptions`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocDefaultSearchEngineOptions/page'),
	},
	
	{
		path: 'api/classes/app/NgDocDefaultSearchEngine',
		title: `NgDocDefaultSearchEngine`,
		loadChildren: () => import('./api/api/classes/app/NgDocDefaultSearchEngine/page'),
	},
	
	{
		path: 'api/classes/app/NgDocRootPage',
		title: `NgDocRootPage`,
		loadChildren: () => import('./api/api/classes/app/NgDocRootPage/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSearchEngine',
		title: `NgDocSearchEngine`,
		loadChildren: () => import('./api/api/classes/app/NgDocSearchEngine/page'),
	},
	
	{
		path: 'api/classes/app/NgDocApiListComponent',
		title: `NgDocApiListComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocApiListComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocBreadcrumbComponent',
		title: `NgDocBreadcrumbComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocBreadcrumbComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocCodeComponent',
		title: `NgDocCodeComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocCodeComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocCopyButtonComponent',
		title: `NgDocCopyButtonComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocCopyButtonComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocDemoComponent',
		title: `NgDocDemoComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocDemoComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocDemoDisplayerComponent',
		title: `NgDocDemoDisplayerComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocDemoDisplayerComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocDemoPaneComponent',
		title: `NgDocDemoPaneComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocDemoPaneComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocFullscreenButtonComponent',
		title: `NgDocFullscreenButtonComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocFullscreenButtonComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocHeadingAnchorComponent',
		title: `NgDocHeadingAnchorComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocHeadingAnchorComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocImageViewerComponent',
		title: `NgDocImageViewerComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocImageViewerComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocKindIconComponent',
		title: `NgDocKindIconComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocKindIconComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocLinkComponent',
		title: `NgDocLinkComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocLinkComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocMermaidViewerComponent',
		title: `NgDocMermaidViewerComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocMermaidViewerComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocNavbarComponent',
		title: `NgDocNavbarComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocNavbarComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPageComponent',
		title: `NgDocPageComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPageComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPageHeaderComponent',
		title: `NgDocPageHeaderComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPageHeaderComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPageLinkComponent',
		title: `NgDocPageLinkComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPageLinkComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPageNavigationComponent',
		title: `NgDocPageNavigationComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPageNavigationComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPageWrapperComponent',
		title: `NgDocPageWrapperComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPageWrapperComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocBasePlayground',
		title: `NgDocBasePlayground`,
		loadChildren: () => import('./api/api/classes/app/NgDocBasePlayground/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPlaygroundComponent',
		title: `NgDocPlaygroundComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPlaygroundComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPlaygroundDemoComponent',
		title: `NgDocPlaygroundDemoComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPlaygroundDemoComponent/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPlaygroundForm',
		title: `NgDocPlaygroundForm`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPlaygroundForm/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPlaygroundPropertiesComponent',
		title: `NgDocPlaygroundPropertiesComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPlaygroundPropertiesComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPlaygroundPropertyComponent',
		title: `NgDocPlaygroundPropertyComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPlaygroundPropertyComponent/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPlaygroundPropertyControl',
		title: `NgDocPlaygroundPropertyControl`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPlaygroundPropertyControl/page'),
	},
	
	{
		path: 'api/classes/app/NgDocCustomNavbarDirective',
		title: `NgDocCustomNavbarDirective`,
		loadChildren: () => import('./api/api/classes/app/NgDocCustomNavbarDirective/page'),
	},
	
	{
		path: 'api/classes/app/NgDocCustomSidebarDirective',
		title: `NgDocCustomSidebarDirective`,
		loadChildren: () => import('./api/api/classes/app/NgDocCustomSidebarDirective/page'),
	},
	
	{
		path: 'api/classes/app/NgDocRootComponent',
		title: `NgDocRootComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocRootComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSearchComponent',
		title: `NgDocSearchComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocSearchComponent/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocSearchDialogData',
		title: `NgDocSearchDialogData`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocSearchDialogData/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSearchDialogComponent',
		title: `NgDocSearchDialogComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocSearchDialogComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSearchResultComponent',
		title: `NgDocSearchResultComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocSearchResultComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSidebarComponent',
		title: `NgDocSidebarComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocSidebarComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocTabsComponent',
		title: `NgDocTabsComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocTabsComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocThemeToggleComponent',
		title: `NgDocThemeToggleComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocThemeToggleComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocTocComponent',
		title: `NgDocTocComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocTocComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocTocElementComponent',
		title: `NgDocTocElementComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocTocElementComponent/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_STORE_THEME_KEY',
		title: `NG_DOC_STORE_THEME_KEY`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_STORE_THEME_KEY/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_DEFAULT_PAGE_PROCESSORS',
		title: `NG_DOC_DEFAULT_PAGE_PROCESSORS`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_DEFAULT_PAGE_PROCESSORS/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_DEFAULT_PAGE_SKELETON',
		title: `NG_DOC_DEFAULT_PAGE_SKELETON`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_DEFAULT_PAGE_SKELETON/page'),
	},
	
	{
		path: 'api/classes/app/NgDocCodeHighlighterDirective',
		title: `NgDocCodeHighlighterDirective`,
		loadChildren: () => import('./api/api/classes/app/NgDocCodeHighlighterDirective/page'),
	},
	
	{
		path: 'api/classes/app/NgDocRouteActiveDirective',
		title: `NgDocRouteActiveDirective`,
		loadChildren: () => import('./api/api/classes/app/NgDocRouteActiveDirective/page'),
	},
	
	{
		path: 'api/functions/app/asBoolean',
		title: `asBoolean`,
		loadChildren: () => import('./api/api/functions/app/asBoolean/page'),
	},
	
	{
		path: 'api/functions/app/createComponent',
		title: `createComponent`,
		loadChildren: () => import('./api/api/functions/app/createComponent/page'),
	},
	
	{
		path: 'api/functions/app/formatHtml',
		title: `formatHtml`,
		loadChildren: () => import('./api/api/functions/app/formatHtml/page'),
	},
	
	{
		path: 'api/functions/app/generateToc',
		title: `generateToc`,
		loadChildren: () => import('./api/api/functions/app/generateToc/page'),
	},
	
	{
		path: 'api/functions/app/isDarkOsTheme',
		title: `isDarkOsTheme`,
		loadChildren: () => import('./api/api/functions/app/isDarkOsTheme/page'),
	},
	
	{
		path: 'api/functions/app/isExternalLink',
		title: `isExternalLink`,
		loadChildren: () => import('./api/api/functions/app/isExternalLink/page'),
	},
	
	{
		path: 'api/functions/app/isPlaygroundProperty',
		title: `isPlaygroundProperty`,
		loadChildren: () => import('./api/api/functions/app/isPlaygroundProperty/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocContext',
		title: `NgDocContext`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocContext/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocDemoAssets',
		title: `NgDocDemoAssets`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocDemoAssets/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocDemoAsset',
		title: `NgDocDemoAsset`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocDemoAsset/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocNavigation',
		title: `NgDocNavigation`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocNavigation/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPageProcessor',
		title: `NgDocPageProcessor`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPageProcessor/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPageNavigation',
		title: `NgDocPageNavigation`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPageNavigation/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPageBreadcrumbs',
		title: `NgDocPageBreadcrumbs`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPageBreadcrumbs/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPageToc',
		title: `NgDocPageToc`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPageToc/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocPageSkeleton',
		title: `NgDocPageSkeleton`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocPageSkeleton/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocProcessorOptions',
		title: `NgDocProcessorOptions`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocProcessorOptions/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocProvidedTypeControl',
		title: `NgDocProvidedTypeControl`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocProvidedTypeControl/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocSearchResult',
		title: `NgDocSearchResult`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocSearchResult/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocTab',
		title: `NgDocTab`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocTab/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocTheme',
		title: `NgDocTheme`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocTheme/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocTocItem',
		title: `NgDocTocItem`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocTocItem/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocTypeControl',
		title: `NgDocTypeControl`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocTypeControl/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocTypeControlProviderOptions',
		title: `NgDocTypeControlProviderOptions`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocTypeControlProviderOptions/page'),
	},
	
	{
		path: 'api/classes/app/NgDocDecodeUriComponentPipe',
		title: `NgDocDecodeUriComponentPipe`,
		loadChildren: () => import('./api/api/classes/app/NgDocDecodeUriComponentPipe/page'),
	},
	
	{
		path: 'api/classes/app/NgDocExtractValuePipe',
		title: `NgDocExtractValuePipe`,
		loadChildren: () => import('./api/api/classes/app/NgDocExtractValuePipe/page'),
	},
	
	{
		path: 'api/classes/app/NgDocFilterByTextPipe',
		title: `NgDocFilterByTextPipe`,
		loadChildren: () => import('./api/api/classes/app/NgDocFilterByTextPipe/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSanitizeHtmlPipe',
		title: `NgDocSanitizeHtmlPipe`,
		loadChildren: () => import('./api/api/classes/app/NgDocSanitizeHtmlPipe/page'),
	},
	
	{
		path: 'api/classes/app/NgDocPageProcessorComponent',
		title: `NgDocPageProcessorComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocPageProcessorComponent/page'),
	},
	
	{
		path: 'api/variables/app/blockquoteProcessor',
		title: `blockquoteProcessor`,
		loadChildren: () => import('./api/api/variables/app/blockquoteProcessor/page'),
	},
	
	{
		path: 'api/variables/app/codeProcessor',
		title: `codeProcessor`,
		loadChildren: () => import('./api/api/variables/app/codeProcessor/page'),
	},
	
	{
		path: 'api/variables/app/demoProcessor',
		title: `demoProcessor`,
		loadChildren: () => import('./api/api/variables/app/demoProcessor/page'),
	},
	
	{
		path: 'api/variables/app/demoPaneProcessor',
		title: `demoPaneProcessor`,
		loadChildren: () => import('./api/api/variables/app/demoPaneProcessor/page'),
	},
	
	{
		path: 'api/variables/app/headingAnchorProcessor',
		title: `headingAnchorProcessor`,
		loadChildren: () => import('./api/api/variables/app/headingAnchorProcessor/page'),
	},
	
	{
		path: 'api/variables/app/iconProcessor',
		title: `iconProcessor`,
		loadChildren: () => import('./api/api/variables/app/iconProcessor/page'),
	},
	
	{
		path: 'api/variables/app/imageProcessor',
		title: `imageProcessor`,
		loadChildren: () => import('./api/api/variables/app/imageProcessor/page'),
	},
	
	{
		path: 'api/variables/app/mermaidProcessor',
		title: `mermaidProcessor`,
		loadChildren: () => import('./api/api/variables/app/mermaidProcessor/page'),
	},
	
	{
		path: 'api/variables/app/playgroundProcessor',
		title: `playgroundProcessor`,
		loadChildren: () => import('./api/api/variables/app/playgroundProcessor/page'),
	},
	
	{
		path: 'api/variables/app/tabsProcessor',
		title: `tabsProcessor`,
		loadChildren: () => import('./api/api/variables/app/tabsProcessor/page'),
	},
	
	{
		path: 'api/variables/app/linkProcessor',
		title: `linkProcessor`,
		loadChildren: () => import('./api/api/variables/app/linkProcessor/page'),
	},
	
	{
		path: 'api/variables/app/tooltipProcessor',
		title: `tooltipProcessor`,
		loadChildren: () => import('./api/api/variables/app/tooltipProcessor/page'),
	},
	
	{
		path: 'api/functions/app/provideMermaid',
		title: `provideMermaid`,
		loadChildren: () => import('./api/api/functions/app/provideMermaid/page'),
	},
	
	{
		path: 'api/functions/app/provideNgDocApp',
		title: `provideNgDocApp`,
		loadChildren: () => import('./api/api/functions/app/provideNgDocApp/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocApplicationConfig',
		title: `NgDocApplicationConfig`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocApplicationConfig/page'),
	},
	
	{
		path: 'api/functions/app/providePageSkeleton',
		title: `providePageSkeleton`,
		loadChildren: () => import('./api/api/functions/app/providePageSkeleton/page'),
	},
	
	{
		path: 'api/functions/app/providePlaygroundDemo',
		title: `providePlaygroundDemo`,
		loadChildren: () => import('./api/api/functions/app/providePlaygroundDemo/page'),
	},
	
	{
		path: 'api/functions/app/getPlaygroundDemoToken',
		title: `getPlaygroundDemoToken`,
		loadChildren: () => import('./api/api/functions/app/getPlaygroundDemoToken/page'),
	},
	
	{
		path: 'api/functions/app/provideSearchEngine',
		title: `provideSearchEngine`,
		loadChildren: () => import('./api/api/functions/app/provideSearchEngine/page'),
	},
	
	{
		path: 'api/functions/app/provideTypeControl',
		title: `provideTypeControl`,
		loadChildren: () => import('./api/api/functions/app/provideTypeControl/page'),
	},
	
	{
		path: 'api/functions/app/getTokenForType',
		title: `getTokenForType`,
		loadChildren: () => import('./api/api/functions/app/getTokenForType/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocHighlighterConfig',
		title: `NgDocHighlighterConfig`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocHighlighterConfig/page'),
	},
	
	{
		path: 'api/classes/app/NgDocHighlighterService',
		title: `NgDocHighlighterService`,
		loadChildren: () => import('./api/api/classes/app/NgDocHighlighterService/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSidebarService',
		title: `NgDocSidebarService`,
		loadChildren: () => import('./api/api/classes/app/NgDocSidebarService/page'),
	},
	
	{
		path: 'api/classes/app/NgDocStoreService',
		title: `NgDocStoreService`,
		loadChildren: () => import('./api/api/classes/app/NgDocStoreService/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_CONTEXT',
		title: `NG_DOC_CONTEXT`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_CONTEXT/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_MERMAID',
		title: `NG_DOC_MERMAID`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_MERMAID/page'),
	},
	
	{
		path: 'api/functions/app/provideMainPageProcessor',
		title: `provideMainPageProcessor`,
		loadChildren: () => import('./api/api/functions/app/provideMainPageProcessor/page'),
	},
	
	{
		path: 'api/functions/app/providePageProcessor',
		title: `providePageProcessor`,
		loadChildren: () => import('./api/api/functions/app/providePageProcessor/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_PAGE_PROCESSOR',
		title: `NG_DOC_PAGE_PROCESSOR`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_PAGE_PROCESSOR/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_PAGE_CUSTOM_PROCESSOR',
		title: `NG_DOC_PAGE_CUSTOM_PROCESSOR`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_PAGE_CUSTOM_PROCESSOR/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_PAGE_SKELETON',
		title: `NG_DOC_PAGE_SKELETON`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_PAGE_SKELETON/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_ROUTE_PREFIX',
		title: `NG_DOC_ROUTE_PREFIX`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_ROUTE_PREFIX/page'),
	},
	
	{
		path: 'api/interfaces/app/NgDocShikiTheme',
		title: `NgDocShikiTheme`,
		loadChildren: () => import('./api/api/interfaces/app/NgDocShikiTheme/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_SHIKI_THEME',
		title: `NG_DOC_SHIKI_THEME`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_SHIKI_THEME/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_THEME',
		title: `NG_DOC_THEME`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_THEME/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_DEFAULT_THEME_ID',
		title: `NG_DOC_DEFAULT_THEME_ID`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_DEFAULT_THEME_ID/page'),
	},
	
	{
		path: 'api/variables/app/NG_DOC_TYPE_CONTROL',
		title: `NG_DOC_TYPE_CONTROL`,
		loadChildren: () => import('./api/api/variables/app/NG_DOC_TYPE_CONTROL/page'),
	},
	
	{
		path: 'api/classes/app/NgDocBooleanControlComponent',
		title: `NgDocBooleanControlComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocBooleanControlComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocNumberControlComponent',
		title: `NgDocNumberControlComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocNumberControlComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocStringControlComponent',
		title: `NgDocStringControlComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocStringControlComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocTypeAliasControlComponent',
		title: `NgDocTypeAliasControlComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocTypeAliasControlComponent/page'),
	},
	
	{
		path: 'api/type-aliases/app/NgDocFormPartialValue',
		title: `NgDocFormPartialValue`,
		loadChildren: () => import('./api/api/type-aliases/app/NgDocFormPartialValue/page'),
	},
	
	{
		path: 'api/classes/app/NgDocThemeService',
		title: `NgDocThemeService`,
		loadChildren: () => import('./api/api/classes/app/NgDocThemeService/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSidebarCategoryComponent',
		title: `NgDocSidebarCategoryComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocSidebarCategoryComponent/page'),
	},
	
	{
		path: 'api/classes/app/NgDocSidebarItemComponent',
		title: `NgDocSidebarItemComponent`,
		loadChildren: () => import('./api/api/classes/app/NgDocSidebarItemComponent/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocActionOutput',
		title: `NgDocActionOutput`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocActionOutput/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocAsset',
		title: `NgDocAsset`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocAsset/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocBuilderContext',
		title: `NgDocBuilderContext`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocBuilderContext/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocCodeBlockParams',
		title: `NgDocCodeBlockParams`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocCodeBlockParams/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocConfiguration',
		title: `NgDocConfiguration`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocConfiguration/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocGuideConfiguration',
		title: `NgDocGuideConfiguration`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocGuideConfiguration/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocKeywordsConfiguration',
		title: `NgDocKeywordsConfiguration`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocKeywordsConfiguration/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocPageKeyword',
		title: `NgDocPageKeyword`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocPageKeyword/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocBuilderOutput',
		title: `NgDocBuilderOutput`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocBuilderOutput/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocPathAnchor',
		title: `NgDocPathAnchor`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocPathAnchor/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocPlaygroundMetadata',
		title: `NgDocPlaygroundMetadata`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocPlaygroundMetadata/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocRendererOptions',
		title: `NgDocRendererOptions`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocRendererOptions/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocRepoConfig',
		title: `NgDocRepoConfig`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocRepoConfig/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocSchema',
		title: `NgDocSchema`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocSchema/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocSnippet',
		title: `NgDocSnippet`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocSnippet/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocSnippetConfig',
		title: `NgDocSnippetConfig`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocSnippetConfig/page'),
	},
	
	{
		path: 'api/functions/builder/buildNgDoc',
		title: `buildNgDoc`,
		loadChildren: () => import('./api/api/functions/builder/buildNgDoc/page'),
	},
	
	{
		path: 'api/functions/builder/createDeclarationMetadata',
		title: `createDeclarationMetadata`,
		loadChildren: () => import('./api/api/functions/builder/createDeclarationMetadata/page'),
	},
	
	{
		path: 'api/functions/builder/createDeclarationTabMetadata',
		title: `createDeclarationTabMetadata`,
		loadChildren: () => import('./api/api/functions/builder/createDeclarationTabMetadata/page'),
	},
	
	{
		path: 'api/functions/builder/createEntryMetadata',
		title: `createEntryMetadata`,
		loadChildren: () => import('./api/api/functions/builder/createEntryMetadata/page'),
	},
	
	{
		path: 'api/functions/builder/createImportPath',
		title: `createImportPath`,
		loadChildren: () => import('./api/api/functions/builder/createImportPath/page'),
	},
	
	{
		path: 'api/functions/builder/createMarkdownMetadata',
		title: `createMarkdownMetadata`,
		loadChildren: () => import('./api/api/functions/builder/createMarkdownMetadata/page'),
	},
	
	{
		path: 'api/functions/builder/getCategorySourceFile',
		title: `getCategorySourceFile`,
		loadChildren: () => import('./api/api/functions/builder/getCategorySourceFile/page'),
	},
	
	{
		path: 'api/functions/builder/getEntryOutDir',
		title: `getEntryOutDir`,
		loadChildren: () => import('./api/api/functions/builder/getEntryOutDir/page'),
	},
	
	{
		path: 'api/functions/builder/getEntryRelativePath',
		title: `getEntryRelativePath`,
		loadChildren: () => import('./api/api/functions/builder/getEntryRelativePath/page'),
	},
	
	{
		path: 'api/functions/builder/getEntryRoute',
		title: `getEntryRoute`,
		loadChildren: () => import('./api/api/functions/builder/getEntryRoute/page'),
	},
	
	{
		path: 'api/functions/builder/getPlaygroundMetadata',
		title: `getPlaygroundMetadata`,
		loadChildren: () => import('./api/api/functions/builder/getPlaygroundMetadata/page'),
	},
	
	{
		path: 'api/functions/builder/getScopedKeyword',
		title: `getScopedKeyword`,
		loadChildren: () => import('./api/api/functions/builder/getScopedKeyword/page'),
	},
	
	{
		path: 'api/functions/builder/getStructuredDocs',
		title: `getStructuredDocs`,
		loadChildren: () => import('./api/api/functions/builder/getStructuredDocs/page'),
	},
	
	{
		path: 'api/interfaces/builder/StructuredDoc',
		title: `StructuredDoc`,
		loadChildren: () => import('./api/api/interfaces/builder/StructuredDoc/page'),
	},
	
	{
		path: 'api/functions/builder/getVariableStatementFromObjectLiteralExpression',
		title: `getVariableStatementFromObjectLiteralExpression`,
		loadChildren: () => import('./api/api/functions/builder/getVariableStatementFromObjectLiteralExpression/page'),
	},
	
	{
		path: 'api/functions/builder/markdownFrontMatter',
		title: `markdownFrontMatter`,
		loadChildren: () => import('./api/api/functions/builder/markdownFrontMatter/page'),
	},
	
	{
		path: 'api/functions/builder/isPageEntry',
		title: `isPageEntry`,
		loadChildren: () => import('./api/api/functions/builder/isPageEntry/page'),
	},
	
	{
		path: 'api/functions/builder/isCategoryEntry',
		title: `isCategoryEntry`,
		loadChildren: () => import('./api/api/functions/builder/isCategoryEntry/page'),
	},
	
	{
		path: 'api/functions/builder/isApiEntry',
		title: `isApiEntry`,
		loadChildren: () => import('./api/api/functions/builder/isApiEntry/page'),
	},
	
	{
		path: 'api/interfaces/builder/DeclarationEntry',
		title: `DeclarationEntry`,
		loadChildren: () => import('./api/api/interfaces/builder/DeclarationEntry/page'),
	},
	
	{
		path: 'api/type-aliases/builder/DeclarationTabEntry',
		title: `DeclarationTabEntry`,
		loadChildren: () => import('./api/api/type-aliases/builder/DeclarationTabEntry/page'),
	},
	
	{
		path: 'api/type-aliases/builder/ContentEntry',
		title: `ContentEntry`,
		loadChildren: () => import('./api/api/type-aliases/builder/ContentEntry/page'),
	},
	
	{
		path: 'api/type-aliases/builder/FileEntry',
		title: `FileEntry`,
		loadChildren: () => import('./api/api/type-aliases/builder/FileEntry/page'),
	},
	
	{
		path: 'api/type-aliases/builder/Entry',
		title: `Entry`,
		loadChildren: () => import('./api/api/type-aliases/builder/Entry/page'),
	},
	
	{
		path: 'api/functions/builder/isPageEntryMetadata',
		title: `isPageEntryMetadata`,
		loadChildren: () => import('./api/api/functions/builder/isPageEntryMetadata/page'),
	},
	
	{
		path: 'api/interfaces/builder/EntryMetadata',
		title: `EntryMetadata`,
		loadChildren: () => import('./api/api/interfaces/builder/EntryMetadata/page'),
	},
	
	{
		path: 'api/type-aliases/builder/ParentEntryMetadata',
		title: `ParentEntryMetadata`,
		loadChildren: () => import('./api/api/type-aliases/builder/ParentEntryMetadata/page'),
	},
	
	{
		path: 'api/interfaces/builder/MarkdownEntry',
		title: `MarkdownEntry`,
		loadChildren: () => import('./api/api/interfaces/builder/MarkdownEntry/page'),
	},
	
	{
		path: 'api/interfaces/builder/TemplateBuilderOutput',
		title: `TemplateBuilderOutput`,
		loadChildren: () => import('./api/api/interfaces/builder/TemplateBuilderOutput/page'),
	},
	
	{
		path: 'api/functions/builder/pageBuilder',
		title: `pageBuilder`,
		loadChildren: () => import('./api/api/functions/builder/pageBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/PAGE_ENTRY_BUILDER_TAG',
		title: `PAGE_ENTRY_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/PAGE_ENTRY_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/variables/builder/GUIDE_PAGE_WRAPPER_BUILDER_TAG',
		title: `GUIDE_PAGE_WRAPPER_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/GUIDE_PAGE_WRAPPER_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/contentBuilder',
		title: `contentBuilder`,
		loadChildren: () => import('./api/api/functions/builder/contentBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/entryBuilder',
		title: `entryBuilder`,
		loadChildren: () => import('./api/api/functions/builder/entryBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/pageComponentBuilder',
		title: `pageComponentBuilder`,
		loadChildren: () => import('./api/api/functions/builder/pageComponentBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/builderState',
		title: `builderState`,
		loadChildren: () => import('./api/api/functions/builder/builderState/page'),
	},
	
	{
		path: 'api/functions/builder/createBuilder',
		title: `createBuilder`,
		loadChildren: () => import('./api/api/functions/builder/createBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/distinctPending',
		title: `distinctPending`,
		loadChildren: () => import('./api/api/functions/builder/distinctPending/page'),
	},
	
	{
		path: 'api/functions/builder/emitCache',
		title: `emitCache`,
		loadChildren: () => import('./api/api/functions/builder/emitCache/page'),
	},
	
	{
		path: 'api/functions/builder/emitFileOutput',
		title: `emitFileOutput`,
		loadChildren: () => import('./api/api/functions/builder/emitFileOutput/page'),
	},
	
	{
		path: 'api/functions/builder/entriesEmitter',
		title: `entriesEmitter`,
		loadChildren: () => import('./api/api/functions/builder/entriesEmitter/page'),
	},
	
	{
		path: 'api/functions/builder/factory',
		title: `factory`,
		loadChildren: () => import('./api/api/functions/builder/factory/page'),
	},
	
	{
		path: 'api/functions/builder/disableCache',
		title: `disableCache`,
		loadChildren: () => import('./api/api/functions/builder/disableCache/page'),
	},
	
	{
		path: 'api/functions/builder/handleCacheStrategy',
		title: `handleCacheStrategy`,
		loadChildren: () => import('./api/api/functions/builder/handleCacheStrategy/page'),
	},
	
	{
		path: 'api/variables/builder/PENDING_CACHE',
		title: `PENDING_CACHE`,
		loadChildren: () => import('./api/api/variables/builder/PENDING_CACHE/page'),
	},
	
	{
		path: 'api/functions/builder/joinBuilderStates',
		title: `joinBuilderStates`,
		loadChildren: () => import('./api/api/functions/builder/joinBuilderStates/page'),
	},
	
	{
		path: 'api/functions/builder/mergeFactory',
		title: `mergeFactory`,
		loadChildren: () => import('./api/api/functions/builder/mergeFactory/page'),
	},
	
	{
		path: 'api/functions/builder/printBuildProgress',
		title: `printBuildProgress`,
		loadChildren: () => import('./api/api/functions/builder/printBuildProgress/page'),
	},
	
	{
		path: 'api/functions/builder/printErrors',
		title: `printErrors`,
		loadChildren: () => import('./api/api/functions/builder/printErrors/page'),
	},
	
	{
		path: 'api/functions/builder/runBuild',
		title: `runBuild`,
		loadChildren: () => import('./api/api/functions/builder/runBuild/page'),
	},
	
	{
		path: 'api/functions/builder/addToStack',
		title: `addToStack`,
		loadChildren: () => import('./api/api/functions/builder/addToStack/page'),
	},
	
	{
		path: 'api/functions/builder/whenStackIsEmpty',
		title: `whenStackIsEmpty`,
		loadChildren: () => import('./api/api/functions/builder/whenStackIsEmpty/page'),
	},
	
	{
		path: 'api/functions/builder/whenBuildersStackIsEmpty',
		title: `whenBuildersStackIsEmpty`,
		loadChildren: () => import('./api/api/functions/builder/whenBuildersStackIsEmpty/page'),
	},
	
	{
		path: 'api/functions/builder/afterBuilders',
		title: `afterBuilders`,
		loadChildren: () => import('./api/api/functions/builder/afterBuilders/page'),
	},
	
	{
		path: 'api/variables/builder/STACK',
		title: `STACK`,
		loadChildren: () => import('./api/api/variables/builder/STACK/page'),
	},
	
	{
		path: 'api/functions/builder/whenBuildersDone',
		title: `whenBuildersDone`,
		loadChildren: () => import('./api/api/functions/builder/whenBuildersDone/page'),
	},
	
	{
		path: 'api/functions/builder/whenDone',
		title: `whenDone`,
		loadChildren: () => import('./api/api/functions/builder/whenDone/page'),
	},
	
	{
		path: 'api/functions/builder/factoryChain',
		title: `factoryChain`,
		loadChildren: () => import('./api/api/functions/builder/factoryChain/page'),
	},
	
	{
		path: 'api/variables/builder/ApiStore',
		title: `ApiStore`,
		loadChildren: () => import('./api/api/variables/builder/ApiStore/page'),
	},
	
	{
		path: 'api/variables/builder/IndexStore',
		title: `IndexStore`,
		loadChildren: () => import('./api/api/variables/builder/IndexStore/page'),
	},
	
	{
		path: 'api/functions/builder/loadGlobalKeywords',
		title: `loadGlobalKeywords`,
		loadChildren: () => import('./api/api/functions/builder/loadGlobalKeywords/page'),
	},
	
	{
		path: 'api/variables/builder/keywordsStore',
		title: `keywordsStore`,
		loadChildren: () => import('./api/api/variables/builder/keywordsStore/page'),
	},
	
	{
		path: 'api/variables/builder/PageStore',
		title: `PageStore`,
		loadChildren: () => import('./api/api/variables/builder/PageStore/page'),
	},
	
	{
		path: 'api/functions/builder/onDependenciesChange',
		title: `onDependenciesChange`,
		loadChildren: () => import('./api/api/functions/builder/onDependenciesChange/page'),
	},
	
	{
		path: 'api/functions/builder/onKeywordsTouch',
		title: `onKeywordsTouch`,
		loadChildren: () => import('./api/api/functions/builder/onKeywordsTouch/page'),
	},
	
	{
		path: 'api/functions/builder/touchKeywords',
		title: `touchKeywords`,
		loadChildren: () => import('./api/api/functions/builder/touchKeywords/page'),
	},
	
	{
		path: 'api/functions/builder/onRemoveFromStore',
		title: `onRemoveFromStore`,
		loadChildren: () => import('./api/api/functions/builder/onRemoveFromStore/page'),
	},
	
	{
		path: 'api/type-aliases/builder/Builder',
		title: `Builder`,
		loadChildren: () => import('./api/api/type-aliases/builder/Builder/page'),
	},
	
	{
		path: 'api/type-aliases/builder/CacheStrategy',
		title: `CacheStrategy`,
		loadChildren: () => import('./api/api/type-aliases/builder/CacheStrategy/page'),
	},
	
	{
		path: 'api/functions/builder/isFileOutput',
		title: `isFileOutput`,
		loadChildren: () => import('./api/api/functions/builder/isFileOutput/page'),
	},
	
	{
		path: 'api/interfaces/builder/FileOutput',
		title: `FileOutput`,
		loadChildren: () => import('./api/api/interfaces/builder/FileOutput/page'),
	},
	
	{
		path: 'api/type-aliases/builder/AsyncFileOutput',
		title: `AsyncFileOutput`,
		loadChildren: () => import('./api/api/type-aliases/builder/AsyncFileOutput/page'),
	},
	
	{
		path: 'api/functions/builder/isBuilderPending',
		title: `isBuilderPending`,
		loadChildren: () => import('./api/api/functions/builder/isBuilderPending/page'),
	},
	
	{
		path: 'api/functions/builder/isBuilderDone',
		title: `isBuilderDone`,
		loadChildren: () => import('./api/api/functions/builder/isBuilderDone/page'),
	},
	
	{
		path: 'api/functions/builder/isBuilderError',
		title: `isBuilderError`,
		loadChildren: () => import('./api/api/functions/builder/isBuilderError/page'),
	},
	
	{
		path: 'api/classes/builder/BuilderPending',
		title: `BuilderPending`,
		loadChildren: () => import('./api/api/classes/builder/BuilderPending/page'),
	},
	
	{
		path: 'api/classes/builder/BuilderDone',
		title: `BuilderDone`,
		loadChildren: () => import('./api/api/classes/builder/BuilderDone/page'),
	},
	
	{
		path: 'api/classes/builder/BuilderError',
		title: `BuilderError`,
		loadChildren: () => import('./api/api/classes/builder/BuilderError/page'),
	},
	
	{
		path: 'api/type-aliases/builder/BuilderState',
		title: `BuilderState`,
		loadChildren: () => import('./api/api/type-aliases/builder/BuilderState/page'),
	},
	
	{
		path: 'api/type-aliases/builder/EndStates',
		title: `EndStates`,
		loadChildren: () => import('./api/api/type-aliases/builder/EndStates/page'),
	},
	
	{
		path: 'api/type-aliases/builder/BuilderStateTuple',
		title: `BuilderStateTuple`,
		loadChildren: () => import('./api/api/type-aliases/builder/BuilderStateTuple/page'),
	},
	
	{
		path: 'api/functions/builder/isMainTrigger',
		title: `isMainTrigger`,
		loadChildren: () => import('./api/api/functions/builder/isMainTrigger/page'),
	},
	
	{
		path: 'api/functions/builder/isSecondaryTrigger',
		title: `isSecondaryTrigger`,
		loadChildren: () => import('./api/api/functions/builder/isSecondaryTrigger/page'),
	},
	
	{
		path: 'api/functions/builder/createMainTrigger',
		title: `createMainTrigger`,
		loadChildren: () => import('./api/api/functions/builder/createMainTrigger/page'),
	},
	
	{
		path: 'api/functions/builder/createSecondaryTrigger',
		title: `createSecondaryTrigger`,
		loadChildren: () => import('./api/api/functions/builder/createSecondaryTrigger/page'),
	},
	
	{
		path: 'api/interfaces/builder/MainTrigger',
		title: `MainTrigger`,
		loadChildren: () => import('./api/api/interfaces/builder/MainTrigger/page'),
	},
	
	{
		path: 'api/interfaces/builder/SecondaryTrigger',
		title: `SecondaryTrigger`,
		loadChildren: () => import('./api/api/interfaces/builder/SecondaryTrigger/page'),
	},
	
	{
		path: 'api/type-aliases/builder/Trigger',
		title: `Trigger`,
		loadChildren: () => import('./api/api/type-aliases/builder/Trigger/page'),
	},
	
	{
		path: 'api/functions/builder/setColdStartFalse',
		title: `setColdStartFalse`,
		loadChildren: () => import('./api/api/functions/builder/setColdStartFalse/page'),
	},
	
	{
		path: 'api/functions/builder/isColdStart',
		title: `isColdStart`,
		loadChildren: () => import('./api/api/functions/builder/isColdStart/page'),
	},
	
	{
		path: 'api/functions/builder/watchFile',
		title: `watchFile`,
		loadChildren: () => import('./api/api/functions/builder/watchFile/page'),
	},
	
	{
		path: 'api/functions/builder/watchFolder',
		title: `watchFolder`,
		loadChildren: () => import('./api/api/functions/builder/watchFolder/page'),
	},
	
	{
		path: 'api/classes/builder/NgDocActions',
		title: `NgDocActions`,
		loadChildren: () => import('./api/api/classes/builder/NgDocActions/page'),
	},
	
	{
		path: 'api/classes/builder/NgDocApi',
		title: `NgDocApi`,
		loadChildren: () => import('./api/api/classes/builder/NgDocApi/page'),
	},
	
	{
		path: 'api/classes/builder/NgDocIndexExtension',
		title: `NgDocIndexExtension`,
		loadChildren: () => import('./api/api/classes/builder/NgDocIndexExtension/page'),
	},
	
	{
		path: 'api/classes/builder/JSDoc',
		title: `JSDoc`,
		loadChildren: () => import('./api/api/classes/builder/JSDoc/page'),
	},
	
	{
		path: 'api/functions/builder/renderTemplate',
		title: `renderTemplate`,
		loadChildren: () => import('./api/api/functions/builder/renderTemplate/page'),
	},
	
	{
		path: 'api/functions/builder/renderTemplateString',
		title: `renderTemplateString`,
		loadChildren: () => import('./api/api/functions/builder/renderTemplateString/page'),
	},
	
	{
		path: 'api/variables/builder/GLOBALS',
		title: `GLOBALS`,
		loadChildren: () => import('./api/api/variables/builder/GLOBALS/page'),
	},
	
	{
		path: 'api/variables/builder/PAGE_NAME',
		title: `PAGE_NAME`,
		loadChildren: () => import('./api/api/variables/builder/PAGE_NAME/page'),
	},
	
	{
		path: 'api/variables/builder/CATEGORY_NAME',
		title: `CATEGORY_NAME`,
		loadChildren: () => import('./api/api/variables/builder/CATEGORY_NAME/page'),
	},
	
	{
		path: 'api/variables/builder/API_NAME',
		title: `API_NAME`,
		loadChildren: () => import('./api/api/variables/builder/API_NAME/page'),
	},
	
	{
		path: 'api/variables/builder/PAGE_PATTERN',
		title: `PAGE_PATTERN`,
		loadChildren: () => import('./api/api/variables/builder/PAGE_PATTERN/page'),
	},
	
	{
		path: 'api/variables/builder/CATEGORY_PATTERN',
		title: `CATEGORY_PATTERN`,
		loadChildren: () => import('./api/api/variables/builder/CATEGORY_PATTERN/page'),
	},
	
	{
		path: 'api/variables/builder/API_PATTERN',
		title: `API_PATTERN`,
		loadChildren: () => import('./api/api/variables/builder/API_PATTERN/page'),
	},
	
	{
		path: 'api/variables/builder/CACHE_PATH',
		title: `CACHE_PATH`,
		loadChildren: () => import('./api/api/variables/builder/CACHE_PATH/page'),
	},
	
	{
		path: 'api/variables/builder/TEMPLATES_PATH',
		title: `TEMPLATES_PATH`,
		loadChildren: () => import('./api/api/variables/builder/TEMPLATES_PATH/page'),
	},
	
	{
		path: 'api/classes/builder/NgDocWatcher',
		title: `NgDocWatcher`,
		loadChildren: () => import('./api/api/classes/builder/NgDocWatcher/page'),
	},
	
	{
		path: 'api/functions/builder/transformIndexHtml',
		title: `transformIndexHtml`,
		loadChildren: () => import('./api/api/functions/builder/transformIndexHtml/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocCachedClass',
		title: `NgDocCachedClass`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocCachedClass/page'),
	},
	
	{
		path: 'api/classes/builder/NgDocCache',
		title: `NgDocCache`,
		loadChildren: () => import('./api/api/classes/builder/NgDocCache/page'),
	},
	
	{
		path: 'api/functions/builder/cachedFilesInitializer',
		title: `cachedFilesInitializer`,
		loadChildren: () => import('./api/api/functions/builder/cachedFilesInitializer/page'),
	},
	
	{
		path: 'api/functions/builder/cachedPropsInitializer',
		title: `cachedPropsInitializer`,
		loadChildren: () => import('./api/api/functions/builder/cachedPropsInitializer/page'),
	},
	
	{
		path: 'api/functions/builder/CachedEntity',
		title: `CachedEntity`,
		loadChildren: () => import('./api/api/functions/builder/CachedEntity/page'),
	},
	
	{
		path: 'api/functions/builder/CachedFilesGetter',
		title: `CachedFilesGetter`,
		loadChildren: () => import('./api/api/functions/builder/CachedFilesGetter/page'),
	},
	
	{
		path: 'api/functions/builder/CachedProperty',
		title: `CachedProperty`,
		loadChildren: () => import('./api/api/functions/builder/CachedProperty/page'),
	},
	
	{
		path: 'api/functions/builder/createCache',
		title: `createCache`,
		loadChildren: () => import('./api/api/functions/builder/createCache/page'),
	},
	
	{
		path: 'api/functions/builder/createCacheForFile',
		title: `createCacheForFile`,
		loadChildren: () => import('./api/api/functions/builder/createCacheForFile/page'),
	},
	
	{
		path: 'api/functions/builder/getCacheDirPath',
		title: `getCacheDirPath`,
		loadChildren: () => import('./api/api/functions/builder/getCacheDirPath/page'),
	},
	
	{
		path: 'api/functions/builder/getCacheFilePath',
		title: `getCacheFilePath`,
		loadChildren: () => import('./api/api/functions/builder/getCacheFilePath/page'),
	},
	
	{
		path: 'api/functions/builder/invalidateCacheIfNeeded',
		title: `invalidateCacheIfNeeded`,
		loadChildren: () => import('./api/api/functions/builder/invalidateCacheIfNeeded/page'),
	},
	
	{
		path: 'api/functions/builder/isCacheValid',
		title: `isCacheValid`,
		loadChildren: () => import('./api/api/functions/builder/isCacheValid/page'),
	},
	
	{
		path: 'api/functions/builder/loadCache',
		title: `loadCache`,
		loadChildren: () => import('./api/api/functions/builder/loadCache/page'),
	},
	
	{
		path: 'api/functions/builder/updateCache',
		title: `updateCache`,
		loadChildren: () => import('./api/api/functions/builder/updateCache/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocCachedType',
		title: `NgDocCachedType`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocCachedType/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocCacheAccessor',
		title: `NgDocCacheAccessor`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocCacheAccessor/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocCachedData',
		title: `NgDocCachedData`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocCachedData/page'),
	},
	
	{
		path: 'api/classes/builder/BaseAction',
		title: `BaseAction`,
		loadChildren: () => import('./api/api/classes/builder/BaseAction/page'),
	},
	
	{
		path: 'api/functions/builder/accessorPresentation',
		title: `accessorPresentation`,
		loadChildren: () => import('./api/api/functions/builder/accessorPresentation/page'),
	},
	
	{
		path: 'api/functions/builder/constructorPresentation',
		title: `constructorPresentation`,
		loadChildren: () => import('./api/api/functions/builder/constructorPresentation/page'),
	},
	
	{
		path: 'api/functions/builder/declarationImport',
		title: `declarationImport`,
		loadChildren: () => import('./api/api/functions/builder/declarationImport/page'),
	},
	
	{
		path: 'api/functions/builder/displayReturnType',
		title: `displayReturnType`,
		loadChildren: () => import('./api/api/functions/builder/displayReturnType/page'),
	},
	
	{
		path: 'api/functions/builder/displayType',
		title: `displayType`,
		loadChildren: () => import('./api/api/functions/builder/displayType/page'),
	},
	
	{
		path: 'api/functions/builder/excludeByJsDocTags',
		title: `excludeByJsDocTags`,
		loadChildren: () => import('./api/api/functions/builder/excludeByJsDocTags/page'),
	},
	
	{
		path: 'api/functions/builder/extractSelectors',
		title: `extractSelectors`,
		loadChildren: () => import('./api/api/functions/builder/extractSelectors/page'),
	},
	
	{
		path: 'api/functions/builder/filterByScope',
		title: `filterByScope`,
		loadChildren: () => import('./api/api/functions/builder/filterByScope/page'),
	},
	
	{
		path: 'api/functions/builder/filterByStatic',
		title: `filterByStatic`,
		loadChildren: () => import('./api/api/functions/builder/filterByStatic/page'),
	},
	
	{
		path: 'api/functions/builder/filterUselessMembers',
		title: `filterUselessMembers`,
		loadChildren: () => import('./api/api/functions/builder/filterUselessMembers/page'),
	},
	
	{
		path: 'api/functions/builder/firstNodeWithComment',
		title: `firstNodeWithComment`,
		loadChildren: () => import('./api/api/functions/builder/firstNodeWithComment/page'),
	},
	
	{
		path: 'api/functions/builder/functionPresentation',
		title: `functionPresentation`,
		loadChildren: () => import('./api/api/functions/builder/functionPresentation/page'),
	},
	
	{
		path: 'api/functions/builder/getAccessorChain',
		title: `getAccessorChain`,
		loadChildren: () => import('./api/api/functions/builder/getAccessorChain/page'),
	},
	
	{
		path: 'api/functions/builder/getClassAccessors',
		title: `getClassAccessors`,
		loadChildren: () => import('./api/api/functions/builder/getClassAccessors/page'),
	},
	
	{
		path: 'api/functions/builder/getClassMethods',
		title: `getClassMethods`,
		loadChildren: () => import('./api/api/functions/builder/getClassMethods/page'),
	},
	
	{
		path: 'api/functions/builder/getClassProperties',
		title: `getClassProperties`,
		loadChildren: () => import('./api/api/functions/builder/getClassProperties/page'),
	},
	
	{
		path: 'api/functions/builder/getDeclarationType',
		title: `getDeclarationType`,
		loadChildren: () => import('./api/api/functions/builder/getDeclarationType/page'),
	},
	
	{
		path: 'api/functions/builder/getImplementedMember',
		title: `getImplementedMember`,
		loadChildren: () => import('./api/api/functions/builder/getImplementedMember/page'),
	},
	
	{
		path: 'api/functions/builder/getInheritedParent',
		title: `getInheritedParent`,
		loadChildren: () => import('./api/api/functions/builder/getInheritedParent/page'),
	},
	
	{
		path: 'api/functions/builder/getInterfaceAccessors',
		title: `getInterfaceAccessors`,
		loadChildren: () => import('./api/api/functions/builder/getInterfaceAccessors/page'),
	},
	
	{
		path: 'api/functions/builder/getInterfaceMethods',
		title: `getInterfaceMethods`,
		loadChildren: () => import('./api/api/functions/builder/getInterfaceMethods/page'),
	},
	
	{
		path: 'api/functions/builder/getInterfaceProperties',
		title: `getInterfaceProperties`,
		loadChildren: () => import('./api/api/functions/builder/getInterfaceProperties/page'),
	},
	
	{
		path: 'api/functions/builder/getJsDocDescription',
		title: `getJsDocDescription`,
		loadChildren: () => import('./api/api/functions/builder/getJsDocDescription/page'),
	},
	
	{
		path: 'api/functions/builder/getJsDocParam',
		title: `getJsDocParam`,
		loadChildren: () => import('./api/api/functions/builder/getJsDocParam/page'),
	},
	
	{
		path: 'api/functions/builder/getJsDocTag',
		title: `getJsDocTag`,
		loadChildren: () => import('./api/api/functions/builder/getJsDocTag/page'),
	},
	
	{
		path: 'api/functions/builder/getJsDocTags',
		title: `getJsDocTags`,
		loadChildren: () => import('./api/api/functions/builder/getJsDocTags/page'),
	},
	
	{
		path: 'api/functions/builder/getMemberParent',
		title: `getMemberParent`,
		loadChildren: () => import('./api/api/functions/builder/getMemberParent/page'),
	},
	
	{
		path: 'api/functions/builder/getMethodChain',
		title: `getMethodChain`,
		loadChildren: () => import('./api/api/functions/builder/getMethodChain/page'),
	},
	
	{
		path: 'api/functions/builder/getOverriddenMember',
		title: `getOverriddenMember`,
		loadChildren: () => import('./api/api/functions/builder/getOverriddenMember/page'),
	},
	
	{
		path: 'api/functions/builder/getPropertyChain',
		title: `getPropertyChain`,
		loadChildren: () => import('./api/api/functions/builder/getPropertyChain/page'),
	},
	
	{
		path: 'api/functions/builder/groupAccessors',
		title: `groupAccessors`,
		loadChildren: () => import('./api/api/functions/builder/groupAccessors/page'),
	},
	
	{
		path: 'api/functions/builder/hasJsDocTag',
		title: `hasJsDocTag`,
		loadChildren: () => import('./api/api/functions/builder/hasJsDocTag/page'),
	},
	
	{
		path: 'api/functions/builder/markdownToHtml',
		title: `markdownToHtml`,
		loadChildren: () => import('./api/api/functions/builder/markdownToHtml/page'),
	},
	
	{
		path: 'api/functions/builder/methodPresentation',
		title: `methodPresentation`,
		loadChildren: () => import('./api/api/functions/builder/methodPresentation/page'),
	},
	
	{
		path: 'api/functions/builder/noEmpty',
		title: `noEmpty`,
		loadChildren: () => import('./api/api/functions/builder/noEmpty/page'),
	},
	
	{
		path: 'api/functions/builder/noLineBreaks',
		title: `noLineBreaks`,
		loadChildren: () => import('./api/api/functions/builder/noLineBreaks/page'),
	},
	
	{
		path: 'api/functions/builder/notEmptyAssets',
		title: `notEmptyAssets`,
		loadChildren: () => import('./api/api/functions/builder/notEmptyAssets/page'),
	},
	
	{
		path: 'api/functions/builder/sortByNodesName',
		title: `sortByNodesName`,
		loadChildren: () => import('./api/api/functions/builder/sortByNodesName/page'),
	},
	
	{
		path: 'api/functions/builder/sortNavigationEntries',
		title: `sortNavigationEntries`,
		loadChildren: () => import('./api/api/functions/builder/sortNavigationEntries/page'),
	},
	
	{
		path: 'api/functions/builder/toTemplateString',
		title: `toTemplateString`,
		loadChildren: () => import('./api/api/functions/builder/toTemplateString/page'),
	},
	
	{
		path: 'api/functions/builder/typeAliasPresentation',
		title: `typeAliasPresentation`,
		loadChildren: () => import('./api/api/functions/builder/typeAliasPresentation/page'),
	},
	
	{
		path: 'api/functions/builder/variablePresentation',
		title: `variablePresentation`,
		loadChildren: () => import('./api/api/functions/builder/variablePresentation/page'),
	},
	
	{
		path: 'api/functions/core/isRoute',
		title: `isRoute`,
		loadChildren: () => import('./api/api/functions/core/isRoute/page'),
	},
	
	{
		path: 'api/functions/core/kebabCase',
		title: `kebabCase`,
		loadChildren: () => import('./api/api/functions/core/kebabCase/page'),
	},
	
	{
		path: 'api/functions/core/objectKeys',
		title: `objectKeys`,
		loadChildren: () => import('./api/api/functions/core/objectKeys/page'),
	},
	
	{
		path: 'api/functions/core/unique',
		title: `unique`,
		loadChildren: () => import('./api/api/functions/core/unique/page'),
	},
	
	{
		path: 'api/functions/builder/apiListComponentBuilder',
		title: `apiListComponentBuilder`,
		loadChildren: () => import('./api/api/functions/builder/apiListComponentBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/API_LIST_COMPONENT_BUILDER_TAG',
		title: `API_LIST_COMPONENT_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/API_LIST_COMPONENT_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/apiListBuilder',
		title: `apiListBuilder`,
		loadChildren: () => import('./api/api/functions/builder/apiListBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/API_LIST_BUILDER_TAG',
		title: `API_LIST_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/API_LIST_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/apiPageTemplateBuilder',
		title: `apiPageTemplateBuilder`,
		loadChildren: () => import('./api/api/functions/builder/apiPageTemplateBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/API_PAGE_TEMPLATE_BUILDER_TAG',
		title: `API_PAGE_TEMPLATE_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/API_PAGE_TEMPLATE_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/variables/builder/API_BUILDER_TAG',
		title: `API_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/API_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/apiBuilder',
		title: `apiBuilder`,
		loadChildren: () => import('./api/api/functions/builder/apiBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/API_ENTRY_BUILDER_TAG',
		title: `API_ENTRY_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/API_ENTRY_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/variables/builder/API_PAGE_WRAPPER_BUILDER_TAG',
		title: `API_PAGE_WRAPPER_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/API_PAGE_WRAPPER_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/renderApiHeader',
		title: `renderApiHeader`,
		loadChildren: () => import('./api/api/functions/builder/renderApiHeader/page'),
	},
	
	{
		path: 'api/functions/builder/routesBuilder',
		title: `routesBuilder`,
		loadChildren: () => import('./api/api/functions/builder/routesBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/contextBuilder',
		title: `contextBuilder`,
		loadChildren: () => import('./api/api/functions/builder/contextBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/ROUTES_BUILDER_TAG',
		title: `ROUTES_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/ROUTES_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/variables/builder/CONTEXT_BUILDER_TAG',
		title: `CONTEXT_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/CONTEXT_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/indexBuilder',
		title: `indexBuilder`,
		loadChildren: () => import('./api/api/functions/builder/indexBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/INDEX_BUILDER_TAG',
		title: `INDEX_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/INDEX_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/globalBuilders',
		title: `globalBuilders`,
		loadChildren: () => import('./api/api/functions/builder/globalBuilders/page'),
	},
	
	{
		path: 'api/functions/builder/keywordsBuilder',
		title: `keywordsBuilder`,
		loadChildren: () => import('./api/api/functions/builder/keywordsBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/searchIndexesBuilder',
		title: `searchIndexesBuilder`,
		loadChildren: () => import('./api/api/functions/builder/searchIndexesBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/demoAssetsBuilder',
		title: `demoAssetsBuilder`,
		loadChildren: () => import('./api/api/functions/builder/demoAssetsBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/PAGE_DEMO_ASSETS_BUILDER_TAG',
		title: `PAGE_DEMO_ASSETS_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/PAGE_DEMO_ASSETS_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/guideHeaderBuilder',
		title: `guideHeaderBuilder`,
		loadChildren: () => import('./api/api/functions/builder/guideHeaderBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/guideTemplateBuilder',
		title: `guideTemplateBuilder`,
		loadChildren: () => import('./api/api/functions/builder/guideTemplateBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/GUIDE_TEMPLATE_BUILDER_TAG',
		title: `GUIDE_TEMPLATE_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/GUIDE_TEMPLATE_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/variables/builder/GUIDE_BUILDER_TAG',
		title: `GUIDE_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/GUIDE_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/playgroundBuilder',
		title: `playgroundBuilder`,
		loadChildren: () => import('./api/api/functions/builder/playgroundBuilder/page'),
	},
	
	{
		path: 'api/variables/builder/PAGE_PLAYGROUND_BUILDER_TAG',
		title: `PAGE_PLAYGROUND_BUILDER_TAG`,
		loadChildren: () => import('./api/api/variables/builder/PAGE_PLAYGROUND_BUILDER_TAG/page'),
	},
	
	{
		path: 'api/functions/builder/renderPageHeader',
		title: `renderPageHeader`,
		loadChildren: () => import('./api/api/functions/builder/renderPageHeader/page'),
	},
	
	{
		path: 'api/functions/builder/pageWrapperBuilder',
		title: `pageWrapperBuilder`,
		loadChildren: () => import('./api/api/functions/builder/pageWrapperBuilder/page'),
	},
	
	{
		path: 'api/functions/builder/resolveAsyncFileOutputs',
		title: `resolveAsyncFileOutputs`,
		loadChildren: () => import('./api/api/functions/builder/resolveAsyncFileOutputs/page'),
	},
	
	{
		path: 'api/functions/builder/onStage',
		title: `onStage`,
		loadChildren: () => import('./api/api/functions/builder/onStage/page'),
	},
	
	{
		path: 'api/type-aliases/builder/BuildStage',
		title: `BuildStage`,
		loadChildren: () => import('./api/api/type-aliases/builder/BuildStage/page'),
	},
	
	{
		path: 'api/functions/builder/watch',
		title: `watch`,
		loadChildren: () => import('./api/api/functions/builder/watch/page'),
	},
	
	{
		path: 'api/functions/builder/apiDetailsAction',
		title: `apiDetailsAction`,
		loadChildren: () => import('./api/api/functions/builder/apiDetailsAction/page'),
	},
	
	{
		path: 'api/functions/builder/apiAction',
		title: `apiAction`,
		loadChildren: () => import('./api/api/functions/builder/apiAction/page'),
	},
	
	{
		path: 'api/functions/builder/demoPaneAction',
		title: `demoPaneAction`,
		loadChildren: () => import('./api/api/functions/builder/demoPaneAction/page'),
	},
	
	{
		path: 'api/functions/builder/demoAction',
		title: `demoAction`,
		loadChildren: () => import('./api/api/functions/builder/demoAction/page'),
	},
	
	{
		path: 'api/functions/builder/jsDocHasTagAction',
		title: `jsDocHasTagAction`,
		loadChildren: () => import('./api/api/functions/builder/jsDocHasTagAction/page'),
	},
	
	{
		path: 'api/functions/builder/jsDocTagAction',
		title: `jsDocTagAction`,
		loadChildren: () => import('./api/api/functions/builder/jsDocTagAction/page'),
	},
	
	{
		path: 'api/functions/builder/jsDocTagsAction',
		title: `jsDocTagsAction`,
		loadChildren: () => import('./api/api/functions/builder/jsDocTagsAction/page'),
	},
	
	{
		path: 'api/functions/builder/jsDocAction',
		title: `jsDocAction`,
		loadChildren: () => import('./api/api/functions/builder/jsDocAction/page'),
	},
	
	{
		path: 'api/functions/builder/playgroundAction',
		title: `playgroundAction`,
		loadChildren: () => import('./api/api/functions/builder/playgroundAction/page'),
	},
	
	{
		path: 'api/functions/builder/buildApiKeywords',
		title: `buildApiKeywords`,
		loadChildren: () => import('./api/api/functions/builder/buildApiKeywords/page'),
	},
	
	{
		path: 'api/functions/builder/getApiAnchorKeywords',
		title: `getApiAnchorKeywords`,
		loadChildren: () => import('./api/api/functions/builder/getApiAnchorKeywords/page'),
	},
	
	{
		path: 'api/functions/builder/getPipeKeywords',
		title: `getPipeKeywords`,
		loadChildren: () => import('./api/api/functions/builder/getPipeKeywords/page'),
	},
	
	{
		path: 'api/functions/builder/getSelectorKeywords',
		title: `getSelectorKeywords`,
		loadChildren: () => import('./api/api/functions/builder/getSelectorKeywords/page'),
	},
	
	{
		path: 'api/functions/builder/buildGuideKeywords',
		title: `buildGuideKeywords`,
		loadChildren: () => import('./api/api/functions/builder/buildGuideKeywords/page'),
	},
	
	{
		path: 'api/functions/builder/getPageAnchorKeywords',
		title: `getPageAnchorKeywords`,
		loadChildren: () => import('./api/api/functions/builder/getPageAnchorKeywords/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocAction',
		title: `NgDocAction`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocAction/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocArtifactProcessor',
		title: `NgDocArtifactProcessor`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocArtifactProcessor/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocComponentAsset',
		title: `NgDocComponentAsset`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocComponentAsset/page'),
	},
	
	{
		path: 'api/interfaces/builder/Constructable',
		title: `Constructable`,
		loadChildren: () => import('./api/api/interfaces/builder/Constructable/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocSupportedDeclaration',
		title: `NgDocSupportedDeclaration`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocSupportedDeclaration/page'),
	},
	
	{
		path: 'api/interfaces/builder/SchematicArgv',
		title: `SchematicArgv`,
		loadChildren: () => import('./api/api/interfaces/builder/SchematicArgv/page'),
	},
	
	{
		path: 'api/functions/builder/generate',
		title: `generate`,
		loadChildren: () => import('./api/api/functions/builder/generate/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocBuildApiSchema',
		title: `NgDocBuildApiSchema`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocBuildApiSchema/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocBuildCategorySchema',
		title: `NgDocBuildCategorySchema`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocBuildCategorySchema/page'),
	},
	
	{
		path: 'api/functions/builder/update',
		title: `update`,
		loadChildren: () => import('./api/api/functions/builder/update/page'),
	},
	
	{
		path: 'api/interfaces/builder/NgDocBuildPageSchema',
		title: `NgDocBuildPageSchema`,
		loadChildren: () => import('./api/api/interfaces/builder/NgDocBuildPageSchema/page'),
	},
	
	{
		path: 'api/functions/builder/extractDefaultExportName',
		title: `extractDefaultExportName`,
		loadChildren: () => import('./api/api/functions/builder/extractDefaultExportName/page'),
	},
	
	{
		path: 'api/functions/builder/findClosestFile',
		title: `findClosestFile`,
		loadChildren: () => import('./api/api/functions/builder/findClosestFile/page'),
	},
	
	{
		path: 'api/functions/builder/getProjectName',
		title: `getProjectName`,
		loadChildren: () => import('./api/api/functions/builder/getProjectName/page'),
	},
	
	{
		path: 'api/functions/builder/getProject',
		title: `getProject`,
		loadChildren: () => import('./api/api/functions/builder/getProject/page'),
	},
	
	{
		path: 'api/functions/builder/getTitle',
		title: `getTitle`,
		loadChildren: () => import('./api/api/functions/builder/getTitle/page'),
	},
	
	{
		path: 'api/functions/builder/modifyTsConfigs',
		title: `modifyTsConfigs`,
		loadChildren: () => import('./api/api/functions/builder/modifyTsConfigs/page'),
	},
	
	{
		path: 'api/functions/builder/varNameValidation',
		title: `varNameValidation`,
		loadChildren: () => import('./api/api/functions/builder/varNameValidation/page'),
	},
	
	{
		path: 'api/functions/builder/migrate',
		title: `migrate`,
		loadChildren: () => import('./api/api/functions/builder/migrate/page'),
	},
	
	{
		path: 'api/type-aliases/builder/NgDocStandalonePagesSchema',
		title: `NgDocStandalonePagesSchema`,
		loadChildren: () => import('./api/api/type-aliases/builder/NgDocStandalonePagesSchema/page'),
	},
	
	{
		path: 'api/functions/builder/updatePages',
		title: `updatePages`,
		loadChildren: () => import('./api/api/functions/builder/updatePages/page'),
	},
	
	{
		path: 'api/variables/ui-kit/expandCollapseAnimation',
		title: `expandCollapseAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/expandCollapseAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/fadeAnimation',
		title: `fadeAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/fadeAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/dropdownOpenAnimation',
		title: `dropdownOpenAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/dropdownOpenAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/dropdownCloseAnimation',
		title: `dropdownCloseAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/dropdownCloseAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/tooltipOpenAnimation',
		title: `tooltipOpenAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/tooltipOpenAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/tooltipCloseAnimation',
		title: `tooltipCloseAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/tooltipCloseAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/notificationOpenAnimation',
		title: `notificationOpenAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/notificationOpenAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/notificationCloseAnimation',
		title: `notificationCloseAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/notificationCloseAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/popupAnimation',
		title: `popupAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/popupAnimation/page'),
	},
	
	{
		path: 'api/variables/ui-kit/preventInitialChildAnimations',
		title: `preventInitialChildAnimations`,
		loadChildren: () => import('./api/api/variables/ui-kit/preventInitialChildAnimations/page'),
	},
	
	{
		path: 'api/variables/ui-kit/tabFadeAnimation',
		title: `tabFadeAnimation`,
		loadChildren: () => import('./api/api/variables/ui-kit/tabFadeAnimation/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocComboboxHostComponent',
		title: `NgDocComboboxHostComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocComboboxHostComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocBlockquoteComponent',
		title: `NgDocBlockquoteComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocBlockquoteComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocButtonComponent',
		title: `NgDocButtonComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocButtonComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocButtonIconComponent',
		title: `NgDocButtonIconComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocButtonIconComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocButtonToggleComponent',
		title: `NgDocButtonToggleComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocButtonToggleComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocCheckboxComponent',
		title: `NgDocCheckboxComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocCheckboxComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocClearControlComponent',
		title: `NgDocClearControlComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocClearControlComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocComboboxComponent',
		title: `NgDocComboboxComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocComboboxComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDataListComponent',
		title: `NgDocDataListComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDataListComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDataListGroupComponent',
		title: `NgDocDataListGroupComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDataListGroupComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/DialogOutletComponent',
		title: `DialogOutletComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/DialogOutletComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDotComponent',
		title: `NgDocDotComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDotComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDropdownComponent',
		title: `NgDocDropdownComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDropdownComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocExpanderComponent',
		title: `NgDocExpanderComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocExpanderComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocFloatedBorderComponent',
		title: `NgDocFloatedBorderComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocFloatedBorderComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocFloatedContentComponent',
		title: `NgDocFloatedContentComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocFloatedContentComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocFocusControlComponent',
		title: `NgDocFocusControlComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocFocusControlComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocIconComponent',
		title: `NgDocIconComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocIconComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocInputWrapperComponent',
		title: `NgDocInputWrapperComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocInputWrapperComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocLabelComponent',
		title: `NgDocLabelComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocLabelComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocLineComponent',
		title: `NgDocLineComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocLineComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocListComponent',
		title: `NgDocListComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocListComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocMagnifierComponent',
		title: `NgDocMagnifierComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocMagnifierComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/MagnifierControllerComponent',
		title: `MagnifierControllerComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/MagnifierControllerComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOptionComponent',
		title: `NgDocOptionComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOptionComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOptionGroupHeaderDirective',
		title: `NgDocOptionGroupHeaderDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOptionGroupHeaderDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOptionGroupComponent',
		title: `NgDocOptionGroupComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOptionGroupComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayContainerComponent',
		title: `NgDocOverlayContainerComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayContainerComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayPointerComponent',
		title: `NgDocOverlayPointerComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayPointerComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocPaneFrontDirective',
		title: `NgDocPaneFrontDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocPaneFrontDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocPaneBackDirective',
		title: `NgDocPaneBackDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocPaneBackDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocPaneComponent',
		title: `NgDocPaneComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocPaneComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocSelectionComponent',
		title: `NgDocSelectionComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocSelectionComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocSelectionHostDirective',
		title: `NgDocSelectionHostDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocSelectionHostDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocSelectionOriginDirective',
		title: `NgDocSelectionOriginDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocSelectionOriginDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocSidenavComponent',
		title: `NgDocSidenavComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocSidenavComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocSmoothResizeComponent',
		title: `NgDocSmoothResizeComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocSmoothResizeComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocSpinnerComponent',
		title: `NgDocSpinnerComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocSpinnerComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTabComponent',
		title: `NgDocTabComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTabComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTabGroupComponent',
		title: `NgDocTabGroupComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTabGroupComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTabRouteComponent',
		title: `NgDocTabRouteComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTabRouteComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTabRoutesGroupComponent',
		title: `NgDocTabRoutesGroupComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTabRoutesGroupComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTagComponent',
		title: `NgDocTagComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTagComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTextComponent',
		title: `NgDocTextComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTextComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTextLeftDirective',
		title: `NgDocTextLeftDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTextLeftDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTextRightDirective',
		title: `NgDocTextRightDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTextRightDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocToggleComponent',
		title: `NgDocToggleComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocToggleComponent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocWrapperComponent',
		title: `NgDocWrapperComponent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocWrapperComponent/page'),
	},
	
	{
		path: 'api/variables/ui-kit/FOCUS_EVENT',
		title: `FOCUS_EVENT`,
		loadChildren: () => import('./api/api/variables/ui-kit/FOCUS_EVENT/page'),
	},
	
	{
		path: 'api/variables/ui-kit/BLUR_EVENT',
		title: `BLUR_EVENT`,
		loadChildren: () => import('./api/api/variables/ui-kit/BLUR_EVENT/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_ALWAYS_FALSE_HANDLER',
		title: `NG_DOC_ALWAYS_FALSE_HANDLER`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_ALWAYS_FALSE_HANDLER/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_DEFAULT_HANDLER',
		title: `NG_DOC_DEFAULT_HANDLER`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_DEFAULT_HANDLER/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_DEFAULT_STRINGIFY',
		title: `NG_DOC_DEFAULT_STRINGIFY`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_DEFAULT_STRINGIFY/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocAutofocusDirective',
		title: `NgDocAutofocusDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocAutofocusDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocCheckedChangeDirective',
		title: `NgDocCheckedChangeDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocCheckedChangeDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDataDirective',
		title: `NgDocDataDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDataDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDropdownHandlerDirective',
		title: `NgDocDropdownHandlerDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDropdownHandlerDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDropdownOriginDirective',
		title: `NgDocDropdownOriginDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDropdownOriginDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocEventSwitcherDirective',
		title: `NgDocEventSwitcherDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocEventSwitcherDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocFocusCatcherDirective',
		title: `NgDocFocusCatcherDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocFocusCatcherDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocFocusableDirective',
		title: `NgDocFocusableDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocFocusableDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocHotkeyDirective',
		title: `NgDocHotkeyDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocHotkeyDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocInputNumberDirective',
		title: `NgDocInputNumberDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocInputNumberDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocInputStringDirective',
		title: `NgDocInputStringDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocInputStringDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocLetDirective',
		title: `NgDocLetDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocLetDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocLetContext',
		title: `NgDocLetContext`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocLetContext/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocRadioGroupDirective',
		title: `NgDocRadioGroupDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocRadioGroupDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocRotatorDirective',
		title: `NgDocRotatorDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocRotatorDirective/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocTooltipDirective',
		title: `NgDocTooltipDirective`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocTooltipDirective/page'),
	},
	
	{
		path: 'api/functions/ui-kit/mergeOverlayConfigs',
		title: `mergeOverlayConfigs`,
		loadChildren: () => import('./api/api/functions/ui-kit/mergeOverlayConfigs/page'),
	},
	
	{
		path: 'api/functions/ui-kit/toElement',
		title: `toElement`,
		loadChildren: () => import('./api/api/functions/ui-kit/toElement/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocCacheInterceptor',
		title: `NgDocCacheInterceptor`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocCacheInterceptor/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocUiConfig',
		title: `NgDocUiConfig`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocUiConfig/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocContextWithImplicit',
		title: `NgDocContextWithImplicit`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocContextWithImplicit/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocOverlayConfig',
		title: `NgDocOverlayConfig`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocOverlayConfig/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocOverlayContainer',
		title: `NgDocOverlayContainer`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocOverlayContainer/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocOverlayProperties',
		title: `NgDocOverlayProperties`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocOverlayProperties/page'),
	},
	
	{
		path: 'api/functions/ui-kit/fromSubscribe',
		title: `fromSubscribe`,
		loadChildren: () => import('./api/api/functions/ui-kit/fromSubscribe/page'),
	},
	
	{
		path: 'api/functions/ui-kit/observableState',
		title: `observableState`,
		loadChildren: () => import('./api/api/functions/ui-kit/observableState/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/StatedObservable',
		title: `StatedObservable`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/StatedObservable/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/ObservableState',
		title: `ObservableState`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/ObservableState/page'),
	},
	
	{
		path: 'api/functions/ui-kit/ngDocZoneAttach',
		title: `ngDocZoneAttach`,
		loadChildren: () => import('./api/api/functions/ui-kit/ngDocZoneAttach/page'),
	},
	
	{
		path: 'api/functions/ui-kit/ngDocZoneDetach',
		title: `ngDocZoneDetach`,
		loadChildren: () => import('./api/api/functions/ui-kit/ngDocZoneDetach/page'),
	},
	
	{
		path: 'api/functions/ui-kit/ngDocZoneOptimize',
		title: `ngDocZoneOptimize`,
		loadChildren: () => import('./api/api/functions/ui-kit/ngDocZoneOptimize/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocAsArrayPipe',
		title: `NgDocAsArrayPipe`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocAsArrayPipe/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocBindPipe',
		title: `NgDocBindPipe`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocBindPipe/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocExecutePipe',
		title: `NgDocExecutePipe`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocExecutePipe/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocHighlightPosition',
		title: `NgDocHighlightPosition`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocHighlightPosition/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocHighlighterPipe',
		title: `NgDocHighlighterPipe`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocHighlighterPipe/page'),
	},
	
	{
		path: 'api/functions/ui-kit/provideNgDocUiKitConfig',
		title: `provideNgDocUiKitConfig`,
		loadChildren: () => import('./api/api/functions/ui-kit/provideNgDocUiKitConfig/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocDialogConfig',
		title: `NgDocDialogConfig`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocDialogConfig/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_DIALOG_DATA',
		title: `NG_DOC_DIALOG_DATA`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_DIALOG_DATA/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDialogService',
		title: `NgDocDialogService`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDialogService/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocNotifyService',
		title: `NgDocNotifyService`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocNotifyService/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayService',
		title: `NgDocOverlayService`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayService/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayStrategy',
		title: `NgDocOverlayStrategy`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayStrategy/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocScrollService',
		title: `NgDocScrollService`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocScrollService/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_ASSETS_PATH',
		title: `NG_DOC_ASSETS_PATH`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_ASSETS_PATH/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_COMPONENT_CONTEXT',
		title: `NG_DOC_COMPONENT_CONTEXT`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_COMPONENT_CONTEXT/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_CUSTOM_ICONS_PATH',
		title: `NG_DOC_CUSTOM_ICONS_PATH`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_CUSTOM_ICONS_PATH/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocHorizontalAlign',
		title: `NgDocHorizontalAlign`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocHorizontalAlign/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocVerticalAlign',
		title: `NgDocVerticalAlign`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocVerticalAlign/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocTextAlign',
		title: `NgDocTextAlign`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocTextAlign/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/BaseElement',
		title: `BaseElement`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/BaseElement/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocBlockquoteType',
		title: `NgDocBlockquoteType`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocBlockquoteType/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocColor',
		title: `NgDocColor`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocColor/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocContent',
		title: `NgDocContent`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocContent/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocComponentContent',
		title: `NgDocComponentContent`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocComponentContent/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocDefineValueFunction',
		title: `NgDocDefineValueFunction`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocDefineValueFunction/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocDisplayValueFunction',
		title: `NgDocDisplayValueFunction`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocDisplayValueFunction/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocGroupFn',
		title: `NgDocGroupFn`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocGroupFn/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocHandler',
		title: `NgDocHandler`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocHandler/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocBooleanHandler',
		title: `NgDocBooleanHandler`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocBooleanHandler/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocStringHandler',
		title: `NgDocStringHandler`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocStringHandler/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocNumberHandler',
		title: `NgDocNumberHandler`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocNumberHandler/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocIconSize',
		title: `NgDocIconSize`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocIconSize/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocOrientation',
		title: `NgDocOrientation`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocOrientation/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocOverlayAnimationEvent',
		title: `NgDocOverlayAnimationEvent`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocOverlayAnimationEvent/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocOverlayOrigin',
		title: `NgDocOverlayOrigin`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocOverlayOrigin/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocOverlayPosition',
		title: `NgDocOverlayPosition`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocOverlayPosition/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocOverlayRelativePosition',
		title: `NgDocOverlayRelativePosition`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocOverlayRelativePosition/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocPosition',
		title: `NgDocPosition`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocPosition/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocSize',
		title: `NgDocSize`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocSize/page'),
	},
	
	{
		path: 'api/interfaces/ui-kit/NgDocSubscribable',
		title: `NgDocSubscribable`,
		loadChildren: () => import('./api/api/interfaces/ui-kit/NgDocSubscribable/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocTagSize',
		title: `NgDocTagSize`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocTagSize/page'),
	},
	
	{
		path: 'api/type-aliases/ui-kit/NgDocTextColor',
		title: `NgDocTextColor`,
		loadChildren: () => import('./api/api/type-aliases/ui-kit/NgDocTextColor/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocFocusUtils',
		title: `NgDocFocusUtils`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocFocusUtils/page'),
	},
	
	{
		path: 'api/variables/ui-kit/NG_DOC_ARROW_MARGIN',
		title: `NG_DOC_ARROW_MARGIN`,
		loadChildren: () => import('./api/api/variables/ui-kit/NG_DOC_ARROW_MARGIN/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayUtils',
		title: `NgDocOverlayUtils`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayUtils/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocPositionUtils',
		title: `NgDocPositionUtils`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocPositionUtils/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocBaseInput',
		title: `NgDocBaseInput`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocBaseInput/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocDisplayValueHost',
		title: `NgDocDisplayValueHost`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocDisplayValueHost/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocInputHost',
		title: `NgDocInputHost`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocInputHost/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocListHost',
		title: `NgDocListHost`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocListHost/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocListItem',
		title: `NgDocListItem`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocListItem/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayHost',
		title: `NgDocOverlayHost`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayHost/page'),
	},
	
	{
		path: 'api/classes/ui-kit/NgDocOverlayRef',
		title: `NgDocOverlayRef`,
		loadChildren: () => import('./api/api/classes/ui-kit/NgDocOverlayRef/page'),
	},
	
	{
		path: 'api/functions/ui-kit/ngDocMakePure',
		title: `ngDocMakePure`,
		loadChildren: () => import('./api/api/functions/ui-kit/ngDocMakePure/page'),
	},
	
	{
		path: 'api/variables/core/NgDocAngularEntities',
		title: `NgDocAngularEntities`,
		loadChildren: () => import('./api/api/variables/core/NgDocAngularEntities/page'),
	},
	
	{
		path: 'api/variables/core/NgDocDeclarations',
		title: `NgDocDeclarations`,
		loadChildren: () => import('./api/api/variables/core/NgDocDeclarations/page'),
	},
	
	{
		path: 'api/variables/core/NG_DOC_ELEMENT',
		title: `NG_DOC_ELEMENT`,
		loadChildren: () => import('./api/api/variables/core/NG_DOC_ELEMENT/page'),
	},
	
	{
		path: 'api/variables/core/NG_DOC_DYNAMIC_SELECTOR',
		title: `NG_DOC_DYNAMIC_SELECTOR`,
		loadChildren: () => import('./api/api/variables/core/NG_DOC_DYNAMIC_SELECTOR/page'),
	},
	
	{
		path: 'api/variables/core/EMPTY_ARRAY',
		title: `EMPTY_ARRAY`,
		loadChildren: () => import('./api/api/variables/core/EMPTY_ARRAY/page'),
	},
	
	{
		path: 'api/variables/core/EMPTY_MAP',
		title: `EMPTY_MAP`,
		loadChildren: () => import('./api/api/variables/core/EMPTY_MAP/page'),
	},
	
	{
		path: 'api/variables/core/EMPTY_FUNCTION',
		title: `EMPTY_FUNCTION`,
		loadChildren: () => import('./api/api/variables/core/EMPTY_FUNCTION/page'),
	},
	
	{
		path: 'api/functions/core/asArray',
		title: `asArray`,
		loadChildren: () => import('./api/api/functions/core/asArray/page'),
	},
	
	{
		path: 'api/functions/core/buildPlaygroundDemoTemplate',
		title: `buildPlaygroundDemoTemplate`,
		loadChildren: () => import('./api/api/functions/core/buildPlaygroundDemoTemplate/page'),
	},
	
	{
		path: 'api/functions/core/buildPlaygroundDemoPipeTemplate',
		title: `buildPlaygroundDemoPipeTemplate`,
		loadChildren: () => import('./api/api/functions/core/buildPlaygroundDemoPipeTemplate/page'),
	},
	
	{
		path: 'api/functions/core/capitalize',
		title: `capitalize`,
		loadChildren: () => import('./api/api/functions/core/capitalize/page'),
	},
	
	{
		path: 'api/functions/core/escapeHtml',
		title: `escapeHtml`,
		loadChildren: () => import('./api/api/functions/core/escapeHtml/page'),
	},
	
	{
		path: 'api/functions/core/escapeRegexp',
		title: `escapeRegexp`,
		loadChildren: () => import('./api/api/functions/core/escapeRegexp/page'),
	},
	
	{
		path: 'api/functions/core/extractFunctionDefaults',
		title: `extractFunctionDefaults`,
		loadChildren: () => import('./api/api/functions/core/extractFunctionDefaults/page'),
	},
	
	{
		path: 'api/functions/core/extractValue',
		title: `extractValue`,
		loadChildren: () => import('./api/api/functions/core/extractValue/page'),
	},
	
	{
		path: 'api/functions/core/extractValueOrThrow',
		title: `extractValueOrThrow`,
		loadChildren: () => import('./api/api/functions/core/extractValueOrThrow/page'),
	},
	
	{
		path: 'api/functions/core/getAssignedInputs',
		title: `getAssignedInputs`,
		loadChildren: () => import('./api/api/functions/core/getAssignedInputs/page'),
	},
	
	{
		path: 'api/functions/core/isIterable',
		title: `isIterable`,
		loadChildren: () => import('./api/api/functions/core/isIterable/page'),
	},
	
	{
		path: 'api/functions/core/isKeyboardEvent',
		title: `isKeyboardEvent`,
		loadChildren: () => import('./api/api/functions/core/isKeyboardEvent/page'),
	},
	
	{
		path: 'api/functions/core/isNodeTag',
		title: `isNodeTag`,
		loadChildren: () => import('./api/api/functions/core/isNodeTag/page'),
	},
	
	{
		path: 'api/functions/core/isPresent',
		title: `isPresent`,
		loadChildren: () => import('./api/api/functions/core/isPresent/page'),
	},
	
	{
		path: 'api/functions/core/isSameObject',
		title: `isSameObject`,
		loadChildren: () => import('./api/api/functions/core/isSameObject/page'),
	},
	
	{
		path: 'api/functions/core/stringify',
		title: `stringify`,
		loadChildren: () => import('./api/api/functions/core/stringify/page'),
	},
	
	{
		path: 'api/functions/core/uid',
		title: `uid`,
		loadChildren: () => import('./api/api/functions/core/uid/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocApi',
		title: `NgDocApi`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocApi/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocApiList',
		title: `NgDocApiList`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocApiList/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocApiListItem',
		title: `NgDocApiListItem`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocApiListItem/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocApiScope',
		title: `NgDocApiScope`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocApiScope/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocBaseEntity',
		title: `NgDocBaseEntity`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocBaseEntity/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocCategory',
		title: `NgDocCategory`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocCategory/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocBaseDemoOptions',
		title: `NgDocBaseDemoOptions`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocBaseDemoOptions/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocDemoActionOptions',
		title: `NgDocDemoActionOptions`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocDemoActionOptions/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocDemoPaneActionOptions',
		title: `NgDocDemoPaneActionOptions`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocDemoPaneActionOptions/page'),
	},
	
	{
		path: 'api/interfaces/core/JSDocMetadata',
		title: `JSDocMetadata`,
		loadChildren: () => import('./api/api/interfaces/core/JSDocMetadata/page'),
	},
	
	{
		path: 'api/variables/core/KEYWORD_ALLOWED_LANGUAGES',
		title: `KEYWORD_ALLOWED_LANGUAGES`,
		loadChildren: () => import('./api/api/variables/core/KEYWORD_ALLOWED_LANGUAGES/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocKeywordLanguage',
		title: `NgDocKeywordLanguage`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocKeywordLanguage/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocKeywordType',
		title: `NgDocKeywordType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocKeywordType/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocKeyword',
		title: `NgDocKeyword`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocKeyword/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocGlobalKeyword',
		title: `NgDocGlobalKeyword`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocGlobalKeyword/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPage',
		title: `NgDocPage`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPage/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPageAnchor',
		title: `NgDocPageAnchor`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPageAnchor/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocScopedKeyword',
		title: `NgDocScopedKeyword`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocScopedKeyword/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocPageAnchorType',
		title: `NgDocPageAnchorType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocPageAnchorType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocPageInfos',
		title: `NgDocPageInfos`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocPageInfos/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPageInfo',
		title: `NgDocPageInfo`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPageInfo/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPlaygroundControlConfig',
		title: `NgDocPlaygroundControlConfig`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPlaygroundControlConfig/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPlaygroundConfig',
		title: `NgDocPlaygroundConfig`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPlaygroundConfig/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPlaygroundContent',
		title: `NgDocPlaygroundContent`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPlaygroundContent/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocPlaygroundProperties',
		title: `NgDocPlaygroundProperties`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocPlaygroundProperties/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPlaygroundProperty',
		title: `NgDocPlaygroundProperty`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPlaygroundProperty/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPlaygroundOptions',
		title: `NgDocPlaygroundOptions`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPlaygroundOptions/page'),
	},
	
	{
		path: 'api/interfaces/core/NgDocPageIndex',
		title: `NgDocPageIndex`,
		loadChildren: () => import('./api/api/interfaces/core/NgDocPageIndex/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocCodeType',
		title: `NgDocCodeType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocCodeType/page'),
	},
	
	{
		path: 'api/type-aliases/core/Constructor',
		title: `Constructor`,
		loadChildren: () => import('./api/api/type-aliases/core/Constructor/page'),
	},
	
	{
		path: 'api/type-aliases/core/AbstractConstructor',
		title: `AbstractConstructor`,
		loadChildren: () => import('./api/api/type-aliases/core/AbstractConstructor/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocDeclarationType',
		title: `NgDocDeclarationType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocDeclarationType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocAngularEntityType',
		title: `NgDocAngularEntityType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocAngularEntityType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocKindType',
		title: `NgDocKindType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocKindType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocExtractedValue',
		title: `NgDocExtractedValue`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocExtractedValue/page'),
	},
	
	{
		path: 'api/type-aliases/core/FunctionType',
		title: `FunctionType`,
		loadChildren: () => import('./api/api/type-aliases/core/FunctionType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocHeading',
		title: `NgDocHeading`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocHeading/page'),
	},
	
	{
		path: 'api/type-aliases/core/InputType',
		title: `InputType`,
		loadChildren: () => import('./api/api/type-aliases/core/InputType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocKeywordsLoader',
		title: `NgDocKeywordsLoader`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocKeywordsLoader/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocPageType',
		title: `NgDocPageType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocPageType/page'),
	},
	
	{
		path: 'api/type-aliases/core/NgDocStyleType',
		title: `NgDocStyleType`,
		loadChildren: () => import('./api/api/type-aliases/core/NgDocStyleType/page'),
	},
	
	{
		path: 'api/classes/core/NgDocHtmlParser',
		title: `NgDocHtmlParser`,
		loadChildren: () => import('./api/api/classes/core/NgDocHtmlParser/page'),
	},
	
	{
		path: 'api/interfaces/keywords-loaders/NgKeywordLoaderOptions',
		title: `NgKeywordLoaderOptions`,
		loadChildren: () => import('./api/api/interfaces/keywords-loaders/NgKeywordLoaderOptions/page'),
	},
	
	{
		path: 'api/interfaces/keywords-loaders/NgPage',
		title: `NgPage`,
		loadChildren: () => import('./api/api/interfaces/keywords-loaders/NgPage/page'),
	},
	
	{
		path: 'api/functions/keywords-loaders/ngKeywordsLoader',
		title: `ngKeywordsLoader`,
		loadChildren: () => import('./api/api/functions/keywords-loaders/ngKeywordsLoader/page'),
	},
	
	{
		path: 'api/interfaces/keywords-loaders/NgDocKeywordsLoaderOptions',
		title: `NgDocKeywordsLoaderOptions`,
		loadChildren: () => import('./api/api/interfaces/keywords-loaders/NgDocKeywordsLoaderOptions/page'),
	},
	
	{
		path: 'api/functions/keywords-loaders/ngDocKeywordsLoader',
		title: `ngDocKeywordsLoader`,
		loadChildren: () => import('./api/api/functions/keywords-loaders/ngDocKeywordsLoader/page'),
	},
	
	{
		path: 'api/type-aliases/keywords-loaders/RxjsVersion',
		title: `RxjsVersion`,
		loadChildren: () => import('./api/api/type-aliases/keywords-loaders/RxjsVersion/page'),
	},
	
	{
		path: 'api/functions/keywords-loaders/rxjsKeywordsLoader',
		title: `rxjsKeywordsLoader`,
		loadChildren: () => import('./api/api/functions/keywords-loaders/rxjsKeywordsLoader/page'),
	},
	
	{
		path: 'api-documentation',
		title: `API Documentation`,
		children: [{
			path: '',
			redirectTo: 'auto-generation',
			pathMatch: 'full'
		},
	{
		path: 'auto-generation',
		title: `Auto generation`,
		loadChildren: () => import('./guides/api-documentation/auto-generation/page'),
	},
	{
		path: 'rendering-api',
		title: `Rendering API`,
		loadChildren: () => import('./guides/api-documentation/rendering-api/page'),
	},
	{
		path: 'documenting-declarations',
		title: `Documenting declarations`,
		loadChildren: () => import('./guides/api-documentation/documenting-declarations/page'),
	},],
	},
	
	{
		path: 'customization',
		title: `Customization`,
		children: [{
			path: '',
			redirectTo: 'themes',
			pathMatch: 'full'
		},
	{
		path: 'themes',
		title: `Themes`,
		loadChildren: () => import('./guides/customization/themes/page'),
	},
	{
		path: 'icons',
		title: `Icons`,
		loadChildren: () => import('./guides/customization/icons/page'),
	},
	{
		path: 'page-skeleton',
		title: `Page Skeleton`,
		loadChildren: () => import('./guides/customization/page-skeleton/page'),
	},
	{
		path: 'layout',
		title: `Layout`,
		loadChildren: () => import('./guides/customization/layout/page'),
	},
	{
		path: 'search',
		title: `Search`,
		loadChildren: () => import('./guides/customization/search/page'),
	},
	{
		path: 'page-processors',
		title: `Page Processors`,
		loadChildren: () => import('./guides/customization/page-processors/page'),
	},],
	},
	
	{
		path: 'getting-started',
		title: `Getting Started`,
		children: [{
			path: '',
			redirectTo: 'ssr',
			pathMatch: 'full'
		},
	{
		path: 'ssr',
		title: `SSR`,
		loadChildren: () => import('./guides/getting-started/ssr/page'),
	},
	{
		path: 'what-is-ng-doc',
		title: `What is NgDoc?`,
		loadChildren: () => import('./guides/getting-started/what-is-ng-doc/page'),
	},
	{
		path: 'migrations',
		title: `Migrations`,
		loadChildren: () => import('./guides/getting-started/migrations/page'),
	},
	{
		path: 'installation',
		title: `Installation`,
		loadChildren: () => import('./guides/getting-started/installation/page'),
	},
	{
		path: 'configuration',
		title: `Configuration`,
		loadChildren: () => import('./guides/getting-started/configuration/page'),
	},],
	},
	
	{
		path: 'writing-content',
		title: `Writing content`,
		children: [{
			path: '',
			redirectTo: 'keywords',
			pathMatch: 'full'
		},
	{
		path: 'keywords',
		title: `Keywords`,
		loadChildren: () => import('./guides/writing-content/keywords/page'),
	},
	{
		path: 'demo-and-demo-pane',
		title: `Demo and Demo Pane`,
		loadChildren: () => import('./guides/writing-content/demo-and-demo-pane/page'),
	},
	{
		path: 'code-blocks',
		title: `Code blocks`,
		loadChildren: () => import('./guides/writing-content/code-blocks/page'),
	},
	{
		path: 'playground',
		title: `Playground`,
		loadChildren: () => import('./guides/writing-content/playground/page'),
	},
	{
		path: 'images-videos-and-embeds',
		title: `Images and embeds`,
		loadChildren: () => import('./guides/writing-content/images-videos-and-embeds/page'),
	},
	{
		path: 'mermaid-diagrams',
		title: `Mermaid diagrams`,
		loadChildren: () => import('./guides/writing-content/mermaid-diagrams/page'),
	},
	{
		path: 'pages-and-categories',
		title: `Pages and categories`,
		loadChildren: () => import('./guides/writing-content/pages-and-categories/page'),
	},
	{
		path: 'blockquotes',
		title: `Blockquotes`,
		loadChildren: () => import('./guides/writing-content/blockquotes/page'),
	},
	{
		path: 'nunjucks',
		title: `Nunjucks`,
		loadChildren: () => import('./guides/writing-content/nunjucks/page'),
	},],
	},
	
	{
		path: 'recipes',
		title: `Recipes`,
		children: [{
			path: '',
			redirectTo: 'ignoring-file-lines',
			pathMatch: 'full'
		},
	{
		path: 'ignoring-file-lines',
		title: `Ignoring file lines`,
		loadChildren: () => import('./guides/recipes/ignoring-file-lines/page'),
	},
	{
		path: 'performance',
		title: `Performance`,
		loadChildren: () => import('./guides/recipes/performance/page'),
	},],
	},
	
	{
		path: 'develop',
		title: `Develop`,
		loadChildren: () => import('./guides/develop/page'),
	},
];
